<template>
  <div
    class="outline-none absolute w-full bg-white border-t hidden lg:block border-t-gray-400 left-0 z-50"
    tabindex="0"
  >
    <atoms-menu
      v-if="hasSubMenu && false"
      class="justify-center border-b border-b-gray-400"
    >
      <atoms-menu-item
        v-for="(item, itemKey) in subMenu"
        :key="itemKey"
        :theme="'to' in item ? 'global-navbar' : 'hover-with-border'"
        :has-background-when-active="false"
        :arrow="false"
        :to="'to' in item ? item.to : null"
        :active="item.title === currVertical"
        padding="xLarge"
        @click.stop="handleCurrVertical(item.title)"
      >
        {{ item.title }}
      </atoms-menu-item>
    </atoms-menu>

    <atoms-menu
      v-if="hasSubMenuObj"
      class="justify-center border-b border-b-gray-400"
    >
      <atoms-menu-item
        v-for="(item, itemKey) in subMenuArr"
        :key="itemKey"
        :has-background-when-active="false"
        :to="subMenuItems?.to ? subMenuItems.to : null"
        :theme="subMenuItems?.to ? 'global-navbar' : 'hover-with-border'"
        :arrow="false"
        :active="item === currVertical"
        padding="xLarge"
        class="!text-dark"
        @click.stop="handleCurrVertical(item)"
      >
        {{ item }}
      </atoms-menu-item>
    </atoms-menu>

    <div v-if="subMenuItems && !subMenuItems?.to">
      <div class="border-b border-b-gray-400">
        <div class="flex w-full max-w-8xxl mx-auto">
          <div class="w-1/2 pl-12 xl:pl-[8.625rem] pr-3 grid grid-cols-2">
            <div class="pt-6 pb-8 pr-3 col-span-1">
              <p class="text-xl mb-4 min-h-[1.75rem] font-bold">
                {{ subMenuItems.firstColumn.title }}
              </p>

              <atoms-menu
                class="space-y-2"
                is-vertical-menu
              >
                <atoms-menu-item
                  v-for="(link, linkKey) in subMenuItems.firstColumn.links"
                  :key="linkKey"
                  :class="{
                    'underline': link.underline
                  }"
                  :to="link.url"
                  theme="rounded"
                  padding="withImageSmall"
                  class="block nav-link"
                  is-vertical-menu
                  @click="() => emit('redirect', { type: 'click' })"
                >
                  <NuxtImg
                    v-if="link.image"
                    width="24"
                    height="24"
                    loading="lazy"
                    class="max-h-24 mr-[1.125rem]"
                    :alt="`cta-inline-${link.title.toLowerCase()}`"
                    :src="link.image"
                  />

                  <span class="text-dark mr-2">
                    {{ link.title }}
                  </span>

                  <span class="w-6 h-6">
                    <IconsChevron
                      class="inline-block"
                      height="12"
                      width="17"
                    />
                  </span>
                </atoms-menu-item>
              </atoms-menu>

              <template v-if="subMenuItems.firstColumn?.subRow?.title">
                <p class="text-xl pt-6 mb-4 min-h-[1.75rem] font-bold">
                  {{ subMenuItems.firstColumn.subRow.title }}
                </p>

                <template v-if="subMenuItems.firstColumn?.subRow?.links">
                  <atoms-menu
                    class="space-y-2"
                    is-vertical-menu
                  >
                    <atoms-menu-item
                      v-for="(link, linkKey) in subMenuItems.firstColumn.subRow.links"
                      :key="linkKey"
                      :class="{
                        'underline': link.underline
                      }"
                      :to="link.url"
                      theme="rounded"
                      padding="withImageSmall"
                      class="block nav-link"
                      is-vertical-menu
                      @click="() => emit('redirect', { type: 'click' })"
                    >
                      <NuxtImg
                        v-if="link.image"
                        width="24"
                        height="24"
                        loading="lazy"
                        class="max-h-24 mr-[1.125rem]"
                        :alt="`cta-inline-${link.title.toLowerCase()}`"
                        :src="link.image"
                      />

                      <span class="text-dark mr-2">
                        {{ link.title }}
                      </span>

                      <span class="w-6 h-6">
                        <IconsChevron
                          class="inline-block"
                          height="12"
                          width="17"
                        />
                      </span>
                    </atoms-menu-item>
                  </atoms-menu>
                </template>
              </template>
            </div>

            <div class="pt-6 pb-8 pr-3 col-span-1">
              <p class="text-xl mb-4 min-h-[1.75rem] font-bold">
                {{ subMenuItems.secondColumn.title }}
              </p>

              <atoms-menu
                class="space-y-2"
                is-vertical-menu
              >
                <atoms-menu-item
                  v-for="(link, linkKey) in subMenuItems.secondColumn.links"
                  :key="linkKey"
                  :class="{
                    'underline': link.underline
                  }"
                  :to="link.url"
                  theme="rounded"
                  padding="withImageSmall"
                  class="block nav-link"
                  is-vertical-menu
                  @click="() => emit('redirect', { type: 'click' })"
                >
                  <NuxtImg
                    v-if="link.image"
                    width="24"
                    height="24"
                    class="max-h-24 mr-[1.125rem]"
                    loading="lazy"
                    :alt="`cta-inline-${link.title.toLowerCase()}`"
                    :src="link.image"
                  />

                  <span class="text-dark mr-2">
                    {{ link.title }}
                  </span>

                  <span class="w-6 h-6">
                    <IconsChevron
                      class="inline-block"
                      height="12"
                      width="17"
                    />
                  </span>
                </atoms-menu-item>
              </atoms-menu>
            </div>

            <div
              v-if="subMenuItems.details"
              class="text-left pt-[1.5rem] pb-[2.5rem] border-t border-t-gray-30 w-full col-span-2"
            >
              Find out more about
              <a
                :href="subMenuItems.details.productPage"
                class="underline"
              >
                <strong>
                  {{ subMenuItems.title }}
                </strong>

                <NuxtImg
                  src="/icons/arrow-line-right.svg"
                  width="20"
                  height="10"
                  class="inline ml-1"
                  alt="arrow"
                />
              </a>
            </div>
          </div>

          <div class="py-6 pl-8 pr-12 xl:pr-[9rem] w-1/2 bg-neutral-100">
            <template v-if="subMenuItems.thirdColumn">
              <p class="text-xl mb-4 min-h-[1.75rem] font-bold">
                {{ subMenuItems.thirdColumn.title }}
              </p>

              <atoms-menu
                class="grid grid-cols-2 gap-4"
                is-vertical-menu
              >
                <atoms-card
                  v-for="(link, linkKey) in featuredMenu(subMenuItems.title, subMenuItems.thirdColumn.links)"
                  :key="linkKey"
                  shadow="none"
                  rounded="md"
                  size="none"
                  class="cursor-pointer border-1 border-white hover:border-1 hover:border-primary w-full min-h-[5.5rem] col-span-1"
                >
                  <nuxt-link
                    :class="{
                      'uppercase underline justify-center': link.underline,
                      'justify-left': !link.underline
                    }"
                    :to="link.url"
                    class="w-full h-full flex items-center p-2
                    font-semibold"
                    @click="() => emit('redirect', { type: 'click' })"
                  >
                    <NuxtImg
                      v-if="link.image"
                      :alt="`cta-inline-partner-${link.title.toLowerCase()}`"
                      :src="link.image.split('?')[0] + '?w=72&h=72&fit=fill&r=8&fm=webp'"
                      width="72"
                      height="72"
                      class="max-h-72 mr-[1.125rem]"
                    />

                    <span
                      v-else-if="!link.underline"
                      class="h-[4.5rem] w-[4.5rem] mr-[1.125rem] rounded-md overflow-hidden relative bg-gray-200"
                    >
                    </span>

                    <!-- eslint-disable vue/no-v-html -->
                    <p
                      :class="{
                        'text-left': !link.underline,
                        'font-bold': link.underline
                      }"
                      v-html="link.title"
                    >
                    </p>

                    <span class="ml-4">
                      <IconsChevron
                        v-show="link.underline"
                        class="inline-block"
                        height="12"
                        width="17"
                      />
                    </span>
                  </nuxt-link>
                </atoms-card>
              </atoms-menu>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'

import { useNavigationStore } from '@/stores/navigation'
const { featuredMenuItems } = useNavigationStore()

defineOptions({
  name: 'OrganismsNavMegaMenu'
})

const props = defineProps({
  menu: {
    type: Object,
    default: () => ({}),
    required: true
  },

  currMenu: {
    type: String,
    default: '',
    required: true
  }
})

const emit = defineEmits([
  'redirect'
])

const currVertical = ref('')

const subMenu = computed(() => props.menu?.menu || {})
const hasSubMenu = computed(() => subMenu.value instanceof Array && subMenu.value.length > 1)

const hasSubMenuObj = computed(() => !_isEmpty(subMenu.value) && Object.keys(subMenu.value).length > 1)

const subMenuArr = computed(() => (!_isEmpty(subMenu.value) && Object.keys(subMenu.value)) || [])

const subMenuItems = computed(() => {
  const temp = subMenu.value[currVertical.value]
  return temp
})

watch(() => props.currMenu, val => {
  if (!val) {
    currVertical.value = ''
  } else {
    currVertical.value = typeof subMenuArr.value[0] !== 'undefined'
      ? subMenuArr.value[0]
      : null
  }
})

onMounted(() => {
  currVertical.value = typeof subMenuArr.value[0] !== 'undefined'
    ? subMenuArr.value[0]
    : null
})

const handleCurrVertical = vertical => {
  currVertical.value = vertical
}

const featuredMenu = (vertical, staticMenuItems) => {
  const menuItems = vertical in featuredMenuItems
    ? featuredMenuItems[vertical]
    : []

  if (!(staticMenuItems instanceof Array && staticMenuItems.length)) {
    return menuItems
  }

  return menuItems.concat(staticMenuItems)
}

</script>
